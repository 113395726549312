import React from "react"
import { isLoggedIn } from '../../services/auth'
import { navigate } from "gatsby"

interface IPrivateRouteProps {
  location?: any;
  component?: any;
};

interface IPrivateRouteState {};

class PrivateRoute extends React.Component<IPrivateRouteProps, IPrivateRouteState> {
  componentDidMount = () => {
    const { location } = this.props
    console.log(isLoggedIn());
    if (!isLoggedIn() && location.pathname !== `/login`) {
      // If the user is not logged in, redirect to the login page.
      navigate(`/login`)
      return null
    }
  }

  render() {
    const { component: Component, location, ...rest } = this.props
    return isLoggedIn() ? <Component {...rest} /> : null
  }
};

export default PrivateRoute;
