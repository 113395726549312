import React from 'react'
import PrivateRoute from '../../components/PrivateRoute/PrivateRoute';
import Dashboard from '../../components/Dashboard/Dashboard';
import { window } from 'global';

const DashboardView = () => (
  <PrivateRoute location={window && window.location} component={Dashboard} />
)

export default DashboardView;
